body {
  overscroll-behavior: none;
  margin: 0;
}

* {
  -webkit-user-select: none;
  user-select: none;
  box-shadow: none;
  touch-action: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-text-size-adjust: none;
  outline: none;
}

html {
  -webkit-text-size-adjust: none;
}

.modal {
  z-index: 100;
  background-color: #0006;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

#popupMessage {
  box-sizing: border-box;
  background-color: #fffc;
  width: 90%;
  padding: 2rem 4rem;
}

#popupMessage #popupButtons {
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

#popupMessage #popupButtons .btn {
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#buttonBar {
  pointer-events: none;
  box-sizing: border-box;
  opacity: 1;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: fixed;
  bottom: 1rem;
  left: 0;
}

#buttonBar .btn {
  min-width: 100%;
  transition: transform 1s ease-in-out;
  transform: translateY(200px);
}

#buttonBar .timebox {
  transition: transform 1s ease-in-out;
  transform: translateY(200px);
}

#buttonBar.open .btn, #buttonBar.open2 .timebox {
  transform: translateY(0);
}

#buttonBar .spacer {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#buttonBar .debugInfo {
  background-color: #efefefcc;
}

#buttonBar .timebox {
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 2em;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

#buttonBar .btn {
  display: none;
}

#myProgress {
  box-sizing: border-box;
  border: 4px solid #000;
  border-radius: 5px;
  width: calc(100% - 40px);
  height: 40px;
  position: absolute;
  left: 20px;
  overflow: hidden;
}

#myBarBackground {
  box-sizing: border-box;
  background-color: #ed8b00;
  width: 100%;
  height: 100%;
  position: absolute;
}

#myBar {
  background-color: green;
  width: 0%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

#score-container {
  pointer-events: none;
  z-index: 100;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 5px;
  font-size: 30px;
  line-height: 1em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.timer-container {
  position: fixed;
}

.centered {
  position: fixed;
  left: 25%;
}

#canvas-container {
  box-sizing: border-box;
  text-align: center;
  position: fixed;
  inset: 0;
}

#background {
  pointer-events: none;
  background-size: cover;
  display: none;
  position: fixed;
  inset: 0;
}

#myCanvas {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: inline;
}

.hidden {
  visibility: hidden;
  display: none !important;
}

.btn {
  pointer-events: all;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 100;
  text-transform: uppercase;
  color: #fff;
  background-color: #483b6f;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  height: 2em;
  line-height: 1em;
  display: flex;
  overflow: hidden;
}

.btn:hover {
  transform: scale(1.05);
}

.btn:active {
  transform: scale(1);
}

/*# sourceMappingURL=index.818676d4.css.map */

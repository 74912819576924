/* ==========================================================================
   Including the external CSS files
   ========================================================================== */

body {
    margin:0;
    overscroll-behavior: none;
}
* {
    user-select: none;
    outline: none;
    box-shadow: none;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
}
html {
    -webkit-text-size-adjust: none
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw; /* Full height */
    overflow: auto; /* Enable scroll if needed */

    background-color: #00000066; /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-content {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #popupMessage {
    background-color: #ffffffcc;
    padding: 2rem 4rem;
    box-sizing: border-box;
    width: 90%;
    #popupButtons {
        display: flex;
        margin-top: 2rem;
        align-items: center;
        justify-content: center;
        .btn {
            flex: 1;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
  }



  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

/* ==========================================================================
   LESS VARIABLES
   ========================================================================== */
//Global colors
@primary: #1d3544;
@secondary: #f78d1e;

//Text colors
@TWhite: #fff;
@TDark: #015a8e;


/* ==========================================================================
   PMC's custom styles
   ========================================================================== */
body {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#buttonBar {
    pointer-events: none;
    position:fixed;
    bottom: 1rem;
    width: 100%;
    padding-left: 10px;
    padding-right:  10px;
    left:0;
    // flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    opacity: 1;
    display: flex;

    .btn {
        transform: translateY(200px);
        transition: transform 1s ease-in-out;
        min-width: 100%;

    }
    .timebox {
        transform: translateY(200px);
        transition: transform 1s ease-in-out;
    }
    &.open {
       .btn {
            transform: translateY(0);
       }

    }
    &.open2 {
        .timebox {
            transform: translateY(0);
        }
    }

    .spacer {
        // flex-grow: 1;
        // flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        // gap: 10px;
        width: 100%;
    }

    .debugInfo {

        background-color: #efefefcc;
    }

    .timebox {
        /*To create image referenced in CSS, use 'Export Selected CSS' command.*/
        background-repeat : no-repeat;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;

        padding-left: 30px;
        padding-right: 30px;
    }

    .btn {
        display: none;
    }

}

#myProgress {
    position: absolute;
    width: calc(100% - 40px);
    left: 20px;
    height: 40px;
    border: 4px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

#myBarBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ED8B00;
    box-sizing: border-box;
}

#myBar {
    position: absolute;
    width: 0%;
    height: 100%;
    // height: 20px;
    background-color: green;
    // transition: width .5s linear;
    overflow: hidden;

}

#score-container {
    pointer-events: none;
    position: fixed;
    // color: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    //background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    line-height: 1em;
    padding-top: 0px;
    padding-bottom: 5px;
}

.timer-container {
    position:fixed;
}

.centered {
    position: fixed;
    left: 25%
}

#canvas-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    text-align: center;

}
#background {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    pointer-events: none;
    background-size: cover;
    display: none;
}
#myCanvas {
    display: inline;
    max-width: 100%;
    height:100%;
    width:100%;
    box-sizing: border-box;
}




/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}


.btn {
    pointer-events: all;

    cursor: pointer;
    display: none;
    flex-grow: 0;
    box-sizing: border-box;

    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;

    text-transform: uppercase;

    line-height : 1em;
    height : 2em;

    color : #FFFFFF;
    background-color : #483B6F;
    &:hover {
        transform: scale(1.05);
    }
    &:active {
        transform: scale(1);
    }
}